
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiOrderDeliveryInfo, apiOrderLogistics, apiOrderDelivery, apiOrderChangeDelivery } from '@/api/goods_order/order'
@Component
export default class OrderLogistics extends Vue {
    @Prop({ default: '5vh' }) top!: string | number //弹窗的距离顶部位置
    @Prop({ default: '0' }) id!: string | number //订单ID
    @Prop({ default: true }) flag!: Boolean //是发货还是物流查询 true为发货 ，false为物流查询
    @Prop({ default: '' }) isShow!: string
    @Prop() orderType: number | undefined
    @Prop({ default: 0 }) express_again!: number

    /** S Data **/
    visible = false //是否

    fullscreenLoading = false //加载方式

    // 物流订单信息
    orderData: any = {
        traces: {}
    }

    // 发货表单
    form: any = {
        send_type: 1, //	是	int	配送方式:1-快递配送;2-无需快递
        express_id: '', //	是(配送方式为1时必选)	int	订单id
        invoice_no: '', //	是(配送方式为1时必选)	int	订单id
        remark: '', //	否	varchar	发货备注
        delivery_content: '', //发货内容
        delivery_content_type: '0',
        delivery_content1: [],
        pay_way: ''
    }

    /** E Data **/

    /** S Method **/

    // 获取订单信息 flag 为 true的时候执行
    getOrderDeliveryInfo() {
        apiOrderDeliveryInfo({ id: this.id }).then(res => {
            this.orderData = res
            this.form.delivery_content = res.delivery_content
            this.fullscreenLoading = false
        })
    }

    // 获取物流查询
    getOrderLogistics() {
        apiOrderLogistics({ id: this.id }).then(res => {
            this.orderData = res
            this.fullscreenLoading = false
        })
    }
    handleAdd() {
        this.form.delivery_content1.push({ name: '', content: '' })
    }
    handleDel(delval: number) {
        this.form.delivery_content1 = this.form.delivery_content1.filter((i: any, index: number) => {
            return index != delval
        })
    }
    // 发货
    orderDelivery() {
        if (this.orderData.order_type == 4) {
            this.form = {
                delivery_content_type: this.form.delivery_content_type,
                delivery_content: this.form.delivery_content,
                delivery_content1: this.form.delivery_content1
            }
        }
        apiOrderDelivery({
            id: this.id,
            ...this.form
        }).then(res => {
            this.$emit('update', '')

            // this.getOrderLogistics()
        })
    }
    // 点击取消
    handleEvent(type: 'cancel' | 'confirm') {
        if (type === 'cancel') {
            this.close()
        }
        if (type === 'confirm') {
            if (this.hasInAfterSalesGoods()) return
            if (this.flag) {
                // 虚拟发货
                if (this.orderData.order_type == 4) {
                    const confirme = this.form.delivery_content1.some((i: any) => {
                        return i.name == '' || i.content == ''
                    })

                    if (this.form.delivery_content.trim() == '' && confirme) {
                        return this.$message.error('请输入发货内容')
                    }
                } else if (this.form.send_type == 1) {
                    if (this.form.express_id == '') {
                        return this.$message.error('请选择快递公司')
                    }
                    if (this.form.invoice_no == '') {
                        return this.$message.error('请填写快递单号')
                    }
                }
            }

            if (
                this.orderData.pay_way == 2 &&
                this.form.send_type == 1 &&
                this.orderData.order_type != 4 &&
                this.checkExpress()
            )
                return

            this.orderDelivery()
            this.close()
        }
    }
    //重新发货
    async handlesendChange() {
        try {
            if (this.form.send_type == 1) {
                if (this.form.express_id == '') {
                    return this.$message.error('请选择快递公司')
                }
                if (this.form.invoice_no == '') {
                    return this.$message.error('请填写快递单号')
                }
                const sendName = this.orderData.express.find((item: any) => {
                    return item.id == this.form.express_id
                })
                await this.$confirm(
                    '仅允许修改一次发货信息，请确认快递信息:' + sendName.name + '-' + this.form.invoice_no
                )
                await apiOrderChangeDelivery({
                    id: this.id,
                    ...this.form
                })
            } else if (this.form.send_type == 2) {
                await this.$confirm('仅允许修改一次发货信息，是否修改为无需快递')
                this.form = {
                    express_id: '',
                    invoice_no: '',
                    delivery_content: this.form.delivery_content
                }
                await apiOrderChangeDelivery({
                    id: this.id,
                    ...this.form
                })
            }
        } catch (error) {
            console.log(error)
        }
        this.close()
        this.$emit('update', '')
    }

    // 打开弹窗
    onTrigger() {
        this.fullscreenLoading = true
        this.flag == true ? this.getOrderDeliveryInfo() : this.getOrderLogistics()
        this.visible = true
    }

    // 关闭弹窗
    close() {
        this.visible = false
    }

    handleChange(val: string) {
        this.form.delivery_content = val.trim()
    }
    // @Watch('isShow')
    // changeShow(value: any) {
    //
    // }
    checkExpress() {
        let express = this.orderData.express.find((item: any) => {
            return item.id == this.form.express_id
        })
        console.log(this.orderData.express, this.form.express_id)

        if (express.code == '') {
            this.$alert(
                `<div>所选的物流公司未填写：<span style="color: red">快递编码</span>，将会影响同步微信小程序发货信息的录入，是否继续发货？</div>`,
                '温馨提示',
                {
                    dangerouslyUseHTMLString: true,
                    distinguishCancelAndClose: false,
                    showCancelButton: true,
                    cancelButtonText: '继续发货',
                    confirmButtonText: '前往设置',
                    showClose: false
                }
            )
                .then(() => {
                    this.toexpress(express.id)
                })
                .catch(() => {
                    this.orderDelivery()
                    this.close()
                    console.log('failer')
                })
            return true
        }
        return false
    }
    hasInAfterSalesGoods() {
        let inAfterSalesGoods = this.orderData.order_goods.filter(
            (goods: Record<string, any>) => goods.after_sale_status === 1
        )

        if (inAfterSalesGoods.length) {
            this.$alert(
                `<div>商品：<span style="color: red">${inAfterSalesGoods[0].goods_name} </span>当前处于售后中，请前往处理</div>`,
                '温馨提示',
                {
                    dangerouslyUseHTMLString: true,
                    distinguishCancelAndClose: true,
                    showCancelButton: true,
                    confirmButtonText: '前往处理'
                }
            )
                .then(() => {
                    this.toAfterSalesDetail(inAfterSalesGoods[0].after_sale_id)
                })
                .catch(() => {
                    console.log('failer')
                })
            return true
        }
        return false
    }

    // 去售后详情
    toAfterSalesDetail(id: any) {
        this.close()
        this.$router.push({
            path: '/order/after_sales_detail',
            query: { id }
        })
    }
    toexpress(id: any) {
        this.close()
        this.$router.push({
            path: '/setting/delivery/express_edit',
            query: { id }
        })
    }
}
